.legend {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-size: 12px;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: 1;
  display: flex;
}

.legend h4 {
  margin: 0 0 10px;
}

.legend div span {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;

  width: 10px;
}

.legend div {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.features {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-size: 12px;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1;
  display: flex;
}

.dataLoader {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.46);
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-size: 26px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: .4s ease-in-out;
  div {
    text-align: center;
  }
}

.credits {
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 1rem;
  bottom: 0.5rem;
  z-index: 1;
  display: flex;
}

.delete {
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  right: 0.125rem;
  top: 0.125rem;
  z-index: 4;
  display: flex;
}

.info {
  background: white;
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 1rem;
  bottom: 50%;
  z-index: 1;
  display: flex;
  width: 200px;
}

.toggles {
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  right: 0.5rem;
  top: 3rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
}


button {
  box-shadow: 0 1px 2px #0000001a;
}


.mapboxgl-hover-custom-popup .mapboxgl-popup-content {
  padding: 0;
  border-radius: .4375rem !important;
}
/* .mapboxgl-hover-custom-popup .mapboxgl-popup-close-button {
  display: none;
} */
.mapboxgl-popup-title {
  padding: 5px 30px 5px 10px;
  font-size: .8em;
  /* background-color: var(--cyan); */
  color: var(--white);
  border-radius: .4375rem .4375rem 0 0;
}
.mapboxgl-popup-container {
  padding: 5px 10px;
  font-size: .8em;
}
.labelvalue {
  font-size: .8em;
}
.labelvalue-label {
  font-size: .8em;
  font-weight: bold;
  margin-right: 1em;
}

.mapboxgl-popup-close-button {
  color: white;
}

.legend-widgetmap {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  padding: 10px;
  font-size: 12px;
  position: absolute;
  left: 1rem;
  top: 1rem;
  z-index: 1;
  display: flex;
  max-width: 15rem;
}

.legend-widgetmap-color {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  padding: 10px;
  font-size: 12px;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: 1;
  display: flex;
}

.legend-widgetmap-color div span {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;
  width: 10px;
}

.widget-modal-tooltip > .tooltip-inner {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 100%;
}